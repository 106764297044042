import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useTranslation, Trans } from "react-i18next";
import Header from "./components/Header";
import Section from "./components/Section";
import ScrollButton from "./components/ScrollButton";
export default function App() {
        const { t, i18n } = useTranslation();

  return (
    <div className="flex md:flex-col bg-brand-green">
      <Navbar></Navbar>
      <div className="w-full flex-col justify-center items-center">
        <Header subtitle={<Trans i18nKey="description.subtitle"></Trans>} />
        {/* <Section
       section='discover'
        title={<Trans i18nKey="description.discovertitle"></Trans>}
        content={<Trans i18nKey="description.discovercontent"></Trans>}
        ></Section>
        <Section
        section='wegerichundwiedehopf'
        title={<Trans i18nKey="description.wegerichundwiedehopftitle"></Trans>}
        content={<Trans i18nKey="description.wegerichundwiedehopfcontent"></Trans>}
        ></Section>
                <Section
        section='events' 
        title={<Trans i18nKey="description.eventstitle"></Trans>}
        content={<Trans i18nKey="description.eventscontent"></Trans>}
        ></Section>
                <Section
        section='cooperation' 
        title={<Trans i18nKey="description.cooperationtitle"></Trans>}
        content={<Trans i18nKey="description.cooperationcontent"></Trans>}
        ></Section>
                <Section
        section='gallery' 
        title={<Trans i18nKey="description.gallerytitle"></Trans>}
        content={<Trans i18nKey="description.gallerytitle"></Trans>}
        >
                <iframe src="http://instagram.com/p/CjOMb1kKJsk/embed" frameborder="0"></iframe>


        </Section> */}
        {/* <Footer></Footer> */}
        <div className="text-3xl text-center text-white">
          Diese Seite wird derzeit überarbeitet. In der Zwischenzeit können Sie
          sich auf der alten Webseite{" "}
          <a
            className="underline text-blue-400"
            href="https://www.stadt-wald-fluss.eu/startseite/"
          >
            stadt-wald-fluss.eu
          </a>{" "}
          über aktuelle Angebote informieren
        </div>
        <ScrollButton></ScrollButton>
      </div>
    </div>
  );
}
