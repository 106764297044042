

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';

const ScrollButton = () => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (

    <div       className={`${visible ? "inline" : "none"} fixed right-10 bottom-10 flex items-end justify-end z-40 text-lg md:text-3xl`}>

        <div className='w-12 h-12 md:w-20 md:h-20 cursor-pointer bg-brand-beige text-white flex items-center justify-center rounded-full' onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon></div>
    </div>
  );
}

export default ScrollButton;
