import { Link } from "react-scroll/modules"
import { useTranslation, Trans } from "react-i18next";
import { Translation } from "react-i18next";
export default function NavbarItem(props) {
  return (
    <div className="flex items-start justify-start pb-2 space-x-2 transition-all duration-300 font-light hover:font-bold cursor-pointer uppercase text-[13px] md:text-sm lg:text-base">
<img className="h-5 md:h-6 lg:h-7 -mt-1" src={require(`../assets/images/schnecke_${props.section}.png`)} alt=""></img>
    <Link
    to={ props.section }
    spy={true}
    smooth={true}
    offset={-50}
    duration={500}
  >
    {<Trans i18nKey={props.name}></Trans>}
  </Link>
  </div>
  );
}
