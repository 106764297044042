

import { useEffect } from "react";
import React, { useState } from "react";
// import {withTranslation}  from '../config/next-i18next';
import { useTranslation, Trans } from "react-i18next";
import { Translation } from "react-i18next";
import NavbarItem from "./NavbarItem";
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import ChangeLanguage from "./ChangeLanguage";
import useWindowDimensions from "./useWindowDimensions";

export default function Navbar() {

  const { width } = useWindowDimensions();

  // scroll 

  // sticky nav

  const [openMenu, setOpenMenu] = useState(() => {
    if (width>768) {
      return true;
    }
    return false;
  });

  const showNewBox = () => {
    setOpenMenu(!openMenu)
}



  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  return (
    <div className={`${scrolled ? "" : "", openMenu ? "transition-all wave duration-300 w-1/2" : "fixed"} md:w-full z-40 p-4 md:mb-4 bg-brand-green`}>
      <nav className={`${scrolled ? "" : "", openMenu ? "" : "", openMenu && scrolled ? "p-4 fixed bg-brand-green " : ""} z-20 md:w-full top-0 left-0 box-border text-white md:p-8 flex flex-col space-y-4 md:flex-row md:justify-evenly md:h-auto`}>
        <a className={`${scrolled ? "" : "", openMenu ? "" : ""} md:invisible transition-all  font-light z-40 cursor-pointer hover:scale-110 flex flex-col `} onClick={() => showNewBox()}><FontAwesomeIcon icon={openMenu ? faXmark : faBars}></FontAwesomeIcon></a>
        {openMenu && <NavbarItem section="discover" name={<Trans i18nKey="description.discover"></Trans>}></NavbarItem>}
        {openMenu && <NavbarItem section="wegerichundwiedehopf" name={<Trans i18nKey="description.wegerichundwiedehopf"></Trans>}></NavbarItem>}
        {openMenu && <NavbarItem section="events" name={<Trans i18nKey="description.events"></Trans>}></NavbarItem>}
        {openMenu && <NavbarItem section="cooperation" name={<Trans i18nKey="description.cooperation"></Trans>}></NavbarItem>}
        

        {openMenu && <NavbarItem section="gallery" name={<Trans i18nKey="description.gallery"></Trans>}></NavbarItem>}
        {/* {openMenu && <NavbarItem section="contact" name={<Trans i18nKey="description.contact"></Trans>}></NavbarItem>} */}
        {/* <div className="hidden md:block">{openMenu && <ChangeLanguage></ChangeLanguage>}</div> */}
        
      </nav>
    </div>
  );

}