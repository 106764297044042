import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'de',
    lng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description: {
            discover: `Discover`,
            wegerichundwiedehopf: `Wegerich & Wiedehopf`,
            events: `Events`,
            gallery: `Gallery`,
            contact: `Contact`,
            cooperation: `Cooperations`,

            imprint: `Imprint`,
            dataprotection: `Data Protection`,
            subtitle: `Exploring Nature and Culture`,
            quote: `You cannot teach a man anything, you can only help him find it within himself.`,
            cooperationtitle: "Cooperations",
            cooperationcontent: "Cooperations",
            discovertitle: "Discover Stadt Land Vielfalt",
            discovercontent: `
            <p>
            Out and about in the Berlin woods, parks and at its waters, in schoolyards and backyards or in Berlin´s
            green surroundings (Brandenburg), but also in museums and artist-studios, STADT-WALD-FLUSS is offering a 
            creative and exciting experience especially for children but also parents and other interested adults in its EXPLORERworkshops 
            or LEARNING- and EXPERIMENTING workshops. New are the so-called NatureTOURS as an activity on offer for adults and families.
            </p>
            <p>
            In the course of these tours we discover the green and wild parts of Berlin - after all, the city in recent years has 
            attracted a lot of wildlife and it continues to do so. But it is also exciting to discover nature on the fringes of 
            the city and the surrounding state of Brandenburg - either by bike or on foot…
            </p>
                     `,
            wegerichundwiedehopftitle: `Wegerich & Wiedehopf`,
            wegerichundwiedehopfcontent: `<p>Experiencing and learning with heart and mind - that is the goal of STADT-WALD-FLUSS. 
            Whether on your way outside or actively involved inside – 
            here is space and time for individual experiences and ideas.</p>
            <p>
            STADT-WALD-FLUSS was founded in 2006 by Cordula Herwig, „Diplompädagogin“ with additional trainings as nature- and environmental teacher. 
            She herself spent most of her childhood roaming through woods and meadows and using garden, kitchen and shed for creative experiments of all sorts. 
            Eventually she became the mother of two sons and soon realized that these experiences cannot be taken for granted anymore. Especially for schoolchildren, 
            free play and experimenting in the outdoors or elsewhere is often no more possible. By founding STADT-WALD-FLUSS she has resolved to do s.th. about this loss of an important quality in modern childhood.
            </p>`,
            eventstitle: `Events`,
            eventscontent: `dd/MM/YY`,
            gallerytitle: `Gallery (Coming Soon)`,
            dataprotectioncontent: `For our Data Privacy Statement (Datenschutzerklärung und Datenschutzhinweise) please read the German version.
            Switch to German`
          }
        }
      },
      de: {
        translation: {
          description: {
            discover: `Entdecken`,
            wegerichundwiedehopf: `wegerich & Wiedehopf`,
            events: `Veranstaltungen`,
            gallery: `Galerie`,
            imprint: `Impressum`,
            contact: `Kontakt`,
            cooperation: `Kooperationen`,
            dataprotection: `Datenschutz`,
            subtitle: `Natur und Kultur erleben in Berlin und Brandenburg`,
            quote: `Man kann einen  Menschen nichts lehren. Man kann ihm nur helfen, es in sich selbst zu entdecken.`,
            cooperationtitle: "Kooperationen",
            cooperationcontent: "Kooperationen",
            discovertitle: "Entdecken Sie Stadt Land Vielfalt",
            discovercontent: `Unterwegs in Berliner Wäldern, Parks und an Gewässern, auf Schul- und Hinterhöfen oder im grünen Umland, aber auch in Museen
                    und Künstlerateliers bzw. in Kooperation mit Einrichtungen wie Kindergärten, Schulen oder Nachbarschaftszentren bietet STADT-WALD-FLUSS
                    seit 2006 kreative und spannende EntdeckerWERKSTÄTTEN bzw. Experimentier- und LernWERKSTÄTTEN für Kinder, aber auch deren Eltern und andere Erwachsene an.
                    Die NaTOUREN sind ein noch neues Angebot für Erwachsene, bzw. für Familien. Bei diesen Touren erkunden wir - per Rad oder auch zu Fuß - nicht nur den Berliner Stadtrand
                    und das Brandenburger Umland. Auch mitten in der Stadt gibt es viel zu entdecken. Berlin ist eben „in“ - das gilt auch für Wildtiere und - teilweise selten gewordene - Pflanzen. 
                    Die Grundidee hinter STADT-WALD-FLUSS ist, dass jeder Mensch letztendlich unterschiedliche Erfahrungen macht und die Welt somit nur auf seine eigene individuelle Weise begreifen kann.
                    Das inhaltliche Angebot wird außerdem von der Idee getragen, daß Natur und Kultur keine Gegensätze sind, sondern sich gegenseitig beeinflussen. Gerade dieses Wechselspiel macht das Entdecken
                    von verschiedensten Phänomenen in Natur und Zivilisation so spannend, da man sowohl auf Altbekanntes, als auch immer wieder auf Neues und Ungeahntes stößt. ,
                    Vielmehr geht es darum, den persönlichen Erfahrungen und Wahrnehmungen (wieder) zu vertrauen bzw. im Rahmen eines angebotenen Themas dem eigenen Interesse zu folgen
                    und auch solche Fragen zu stellen, auf die es vielleicht nicht immer eine klare Antwort gibt.`,
                    wegerichundwiedehopftitle: `Netzwerk Wegerich & Wiedehopf`,
                    wegerichundwiedehopfcontent: `Lernen und Erleben mit "Sinn und Verstand", das ist das Ziel von STADT-WALD-FLUSS. Egal, ob draussen unterwegs oder drinnen aktiv - hier gibt es Raum und Zeit für eigene Erfahrungen und Ideen.
            STADT-WALD-FLUSS wurde 2006 von Cordula Herwig (Diplompädagogin, Natur- und Umweltpädagogin) gegründet. Sie selbst hat einen Großteil ihrer Kindheit damit zugebracht, durch Wälder und Wiesen zu streifen und Garten, 
            Küche und Schuppen für kreative Experimente aller Art zu nutzen. Erfahrungen, die für Menschen in der Stadt - und mittlerweile auch zunehmend auf "dem Land" -  immer weniger selbstverständlich sind. Besonders für Kinder gibt es immer weniger Raum und Möglichkeiten für ein "artgerechtes" Aufwachsen mit und in der Natur. Gerade  für viele Kinder ist gibt es zunehmend weniger Möglichkeiten für freies Spielen und Experimentieren draußen in der Natur.
            STADT-WALD-FLUSS hat sich daher zur Aufgabe gemacht, Kindern und Erwachsenen diese Erfahrungen in entsprechenden Freiräumen draußen und drinnen wieder ein Stück weit näherzubringen.
            Aus dieser Idee sind bis heute drei verschiedene Arten von Angeboten hervorgegangen:
            
                Naturentdeckerwerkstätten  als Freizeitangebote für Kinder und Familien in Berlin
                Natur- und Umweltbildungsangebote  für Einrichtungen in Berlin
                NaTOUREN für Erwachsene und Familien in Berlin und Brandenburg
            
            In Kooperation mit Quartiersbüros in den Berliner Bezirken Kreuzberg und Mitte war STADT-WALD-FLUSS mit beteiligt am Aufbau naturwissenschaftlicher Lernwerkstätten in Kitas und Schulen.
            
            Außerdem bietet STADT-WALD-FLUSS regelmäßig natur- und umweltpädagogische Aktivitäten in Kooperation mit  verschiedenen Naturschutzzentren, im Berliner Stadtwald und in öffentlichen Parkanlagen an.
            
            Im Krausnickpark in Berlin - Mitte ist Cordula Herwig auch im Bürgerpark Krausnickdreieck aktiv und in diesem Rahmen mit beteiligt an der Pflege und Gestaltung des Parks und der Mieterbeete.`,
            eventstitle: `Veranstaltungen`,
            eventscontent: `TT/MM/JAHR`,
            gallerytitle: `Galerie (Kommt Bald)`,
            dataprotectioncontent: `Datenschutz`
          }
        }
      }
    }
  });

export default i18n;
