
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'

import { useTranslation, Trans } from "react-i18next";
import i18n from "../i18n";
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'

export default function Header(props) {
  
  const { i18n } = useTranslation();


  return (
<div className='bg-brand-green text-white flex items-center justify-center'>



    <div id="top" className="flex flex-col items-center">
      <div className="hidden md:block text-[20px] 2xl:text-[80px] xl:text-[60px] lg:text-[40px] md:text-[30px] sm:text-[20px] uppercase flex items-center justify-evenly space-x-6">
        <span>Stadt</span>
        <FontAwesomeIcon icon={faAnglesRight}></FontAwesomeIcon>
        <span>Land</span>
        <FontAwesomeIcon icon={faAnglesRight}></FontAwesomeIcon>
        <span>Vielfalt</span>
      </div>
      <h2 className="hidden md:block text-sm 2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl sm:text-lg uppercase">{props.subtitle}</h2>
    
    <div className='flex flex-col items-center justify-center space-y-6  p-4 md:p-12'>
      <div className='rounded-full bg-white w-1/2 p-2 sm:p-4'>
<img className="" src={require('../assets/images/Logo.png')}  alt="mockup"></img>
</div>
      <div className='text-[11px] sm:text-base md:text-lg lg:text-xl space-y-4 space-x-2'>
          {/* <div className="text-left"><FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon></div> */}
          <div className="px-4 text-center" ><Trans i18nKey="description.quote"></Trans></div>
          {/* <div className="text-right"><FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon></div> */}
          <p className="italic text-center"> - Galileo Galilei</p>

      </div>
      </div>

    </div>
    </div>
  );
}
